import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { Container } from 'react-bootstrap';
import NeroForm from './NeroForm';
import GiftIdeasPage from './GiftIdeasPage';
import gpt3Request from './gpt3';
import Banner from './Banner';
import FAQ from './FAQ';
import AboutUs from './AboutUs';

function App() {
  const [giftIdeas, setGiftIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [characteristics, setCharacteristics] = useState('');

  const handleFormSubmit = async (formData) => {
    resetZoom();
    setLoading(true);
    const newCharacteristics = `${formData.age} year old ${formData.gender} who's interests are ${formData.interests} with a budget of ${formData.budget} for this occasion: ${formData.occupation}`;
    setCharacteristics(newCharacteristics);
    const ideas = await gpt3Request(newCharacteristics);
    setGiftIdeas(ideas);
    setLoading(false);
  };
  
  const resetZoom = () => {
  const viewport = document.querySelector('meta[name="viewport"]');
  viewport.setAttribute(
    'content',
    'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1'
  );
};


  const handleGenerateMoreIdeas = async () => {
    setLoading(true);
    const newIdeas = await gpt3Request(characteristics, giftIdeas);
    setGiftIdeas(newIdeas);
    setLoading(false);
  };
  
  return (
    <Router>
      <Container className="App">
        <Banner />
        <Routes>
          <Route
            path="/faq"
            element={<FAQ />}
          />

          <Route
            exact
            path="/"
            element={
              <>
                <h1>Gift Idea Generator</h1>
                <h2>Powered by Artificial Intelligence</h2>
                <NeroForm onSubmit={handleFormSubmit} />
              </>
            }
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/gift-ideas"
            element={
              <GiftIdeasPage
                giftIdeas={giftIdeas}
                showTextArea={giftIdeas.length > 0}
                onGenerateMoreIdeas={handleGenerateMoreIdeas}
                loading={loading}
              />
            }
          />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
