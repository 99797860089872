import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./Banner.css";

const Banner = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="banner">
      <div className="banner-content">
        <div className="brand">
          <Link to="/">
            <img src="https://i.postimg.cc/wTHkCFGk/My-project.png" alt="Nero Logo" className="brand-logo" />
          </Link>
          NERO 
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className="menu-line"></div>
          <div className="menu-line"></div>
          <div className="menu-line"></div>
        </div>
        {menuOpen && (
          <div className="menu">
            <Link to="/" className="menu-item" onClick={toggleMenu}>Home</Link>
            <Link to="/about-us" className="menu-item" onClick={toggleMenu}>About Us</Link>
            <Link to="/faq" className="menu-item" onClick={toggleMenu}>FAQ</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
