import React from 'react';

const Loading = () => {
  return (
    <div className="loading-container">
      <img
                
     src="https://s10.gifyu.com/images/Finnnnnnn.gif"
        alt="Doing the heavy thinking for you..."
        className="loading-gif"
      />
      <p>Fetching you some purrsonalized gift ideas! This could take up to 30 seconds...</p>
    </div>
  );
};

export default Loading;
