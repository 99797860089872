import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const faqList = [
    {
      id: 1,
      question: 'What is the Gift Idea Generator?',
      answer: 'The Gift Idea Generator is a user-friendly website that utilizes GPT-3 technology to help you find the perfect gift ideas based on the recipient\'s age, gender, budget, interests, and the occasion. Our goal is to make gift-giving easier and more enjoyable by providing tailored suggestions for any situation.',
    },
    {
      id: 2,
      question: 'How does the Gift Idea Generator work?',
      answer: 'The Gift Idea Generator uses GPT-3, a powerful language model developed by OpenAI, to process your inputs and generate relevant gift ideas. Once you provide the recipient\'s age, gender, budget, interests, and occasion, our algorithm will analyze this information and present you with a list of suitable gift ideas.',
    },
    {
      id: 3,
      question: 'How do I use the Gift Idea Generator?',
      answer: 'To use the Gift Idea Generator, simply follow these steps:\n\n  a. Visit our website and locate the input fields.\n  b. Select the recipient\'s age, gender, and budget using the scrollable fields.\n  c. Type in the recipient\'s interests and the occasion in the designated input fields.\n  d. Click the \'Generate Gift Ideas\' button to receive a list of tailored suggestions.',
    },
    {
      id: 4,
      question: 'Is the Gift Idea Generator free to use?',
      answer: 'Yes, our Gift Idea Generator is completely free to use. We believe that everyone should have access to the best gift ideas without any added costs.',
    },
    {
      id: 5,
      question: 'How accurate are the gift suggestions?',
      answer: 'Our Gift Idea Generator uses advanced GPT-3 technology to provide accurate and relevant gift suggestions based on the inputs you provide. While the results are generally tailored and useful, individual preferences may vary. We recommend using the generated ideas as a starting point and adding your personal touch to ensure the perfect gift.',
    },
    {
      id: 6,
      question: 'How do you ensure the privacy of my data?',
      answer: 'We take data privacy seriously. The information you provide is only used to generate gift ideas and is not stored or shared with third parties. For more information, please review our Privacy Policy.',
    },
    {
      id: 7,
      question: 'What is the best way to get accurate gift suggestions?',
      answer: 'To obtain the most accurate gift suggestions, it\'s essential to provide as much relevant information as possible. The more interests you include, the better our Gift Idea Generator will be able to tailor its recommendations to the recipient\'s preferences. Don\'t hesitate to be specific when describing the recipient\'s hobbies or areas of interest, as this will help our algorithm generate the most suitable gift ideas.',
    },
    {
      id: 8,
      question: 'Amazon Associates Disclosure',
      answer: 'As an Amazon Associate, our website earns from qualifying purchases made through the provided affiliate links. This means that some of the gift ideas generated by our Gift Idea Generator may include Amazon product links. When you make a purchase through these links, we may receive a commission at no additional cost to you. This helps support our website and allows us to continue offering our free gift idea generation service. Please note that we only recommend products that we believe are suitable and relevant based on the inputs you provide.',
},
];
  return (
    <div className="faq-container">
      <h1>FAQ</h1>
      <ul className="faq-list">
        {faqList.map((faq) => (
          <li key={faq.id} className="faq-item">
            <h2>{faq.question}</h2>
            <p>{faq.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;