import axios from "axios";

const configuration = {
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
};

const prompt = (characteristics, existingIdeas = []) => {
  const basePrompt = `You are NERO, the gift ideas generating cat. List 7 unique gift ideas with a very thoughtful explanation for each idea for a ${characteristics}. Make sure to seperate the gift idea from the explanation with a colon`;
  
  if (existingIdeas.length > 0) {
    return `${basePrompt} Please suggest some unique gift ideas that are not in the following list: ${existingIdeas.join(', ')}`;
  }
  
  return basePrompt;
};

const gpt3Request = async (characteristics, existingIdeas = []) => {
  const response = await axios.post(
    "https://api.openai.com/v1/chat/completions",
    {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "user",
          content: prompt(characteristics, existingIdeas),
        },
      ],
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${configuration.apiKey}`,
      },
    }
  );
  const ideas = response.data.choices[0].message.content
    .trim()
    .split(/\d+\./) // Updated splitting method
    .slice(1) // Remove the first empty element
    .map((idea) => idea.trim()); // Trim each idea

  return ideas;
};

export default gpt3Request;
