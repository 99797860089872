import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NeroForm.module.css';

const NeroForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    interests: '',
    occupation: '',
    budget: '',
  });
 const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

const handleSubmit = (event) => {
  event.preventDefault();
  onSubmit(formData);
  navigate('/gift-ideas');

  // Scroll to the top of the page
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};


  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <p>Want some gift ideas? We've got you covered! Tell us a bit about the person you're shopping for - their age, gender, interests, budget, and the occasion - and we'll give you some personalized recommendations!</p>
      <div className={styles.formGroup}>
        <label htmlFor="age" className={styles.formLabel}>Age:</label>
        <select name="age" id="age" value={formData.age} onChange={handleChange} className={styles.formControl}>
          <option value="">Select age</option>
          {Array.from({ length: 100 }, (_, i) => i + 1).map((age) => (
            <option key={age} value={age}>
              {age}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="gender" className={styles.formLabel}>Gender:</label>
        <select name="gender" id="gender" value={formData.gender} onChange={handleChange} className={styles.formControl}>
          <option value="">Select gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="non-binary">Non-binary</option>
          <option value="other">Other</option>
        </select>
      </div>
      <div className={styles.formGroup}>
  <label htmlFor="budget" className={styles.formLabel}>Budget:</label>
  <select name="budget" id="budget" value={formData.budget} onChange={handleChange} className={styles.formControl}>
    <option value="">Select budget</option>
    <option value="10">10</option>
    <option value="25">25</option>
    <option value="50">50</option>
    <option value="100">100</option>
    <option value="250">250</option>
    <option value="Any">Any</option>
  </select>
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="interests" className={styles.formLabel}>Interests - add several for better ideas:</label>
        <input
          type="text"
          name="interests"
          id="interests"
          value={formData.interests}
          onChange={handleChange}
          className={styles.formControl}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="occupation" className={styles.formLabel}>Occasion:</label>
        <input
          type="text"
          name="occupation"
          id="occupation"
          value={formData.occupation}
          onChange={handleChange}
          className={styles.formControl}
        />
      </div>

      <button type="submit" className={styles.submitButton}>Submit</button>
    </form>
  );
};

export default NeroForm;
