import React from 'react';
import './GiftList.css';

const GiftList = ({ giftIdeas }) => {
  const formattedIdeas = giftIdeas.map((idea) => {
    // Regular expression to match text before and after all colons
    const match = idea.match(/(.*?)[\s]*([:])[\s]*(.*)/);

    const createAffiliateLink = (product) => {
      const baseUrl = "https://www.amazon.com/s";
      const encodedProduct = encodeURIComponent(product);
      const linkParams = "crid=260VSC8HYIHRP&sprefix=%252Caps%252C94&ref=nb_sb_ss_recent_1_0_recent";
      const affiliateParams = "_encoding=UTF8&tag=alejandro835-20&linkCode=ur2&linkId=0a995f0365b0cb9850ea98f6063d62c3&camp=1789&creative=9325";

      return `${baseUrl}?k=${encodedProduct}&${linkParams}&${affiliateParams}`;
    };

    if (match) {
      const boldPart = match[1].trim();
      const normalPart = match[2] + " " + match[3];
      const affiliateLink = createAffiliateLink(boldPart);

      return (
        <div key={idea}>
          <p>
            <strong>{boldPart}</strong>
            {normalPart}
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={affiliateLink}
            >
              Find {boldPart} on Amazon
            </a>
          </p>
        </div>
      );
    }

    return (
      <div key={idea}>
        <p>{idea}</p>
      </div>
    );
  });

  return <div className="gift-list-container">{formattedIdeas}</div>;
};

export default GiftList;
