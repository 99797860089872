import React from 'react';
import { Container } from 'react-bootstrap';
import './AboutUs.css';

const AboutUs = () => {
  const content = `Welcome to NERO, the Gift Idea Generator powered by a cat! You might be wondering, "Why a cat?" Well, let us tell you the delightful tale behind the creation of our unique platform.

Once upon a time, in a world filled with uninspired and repetitive gifts, a group of friends struggled to find the perfect presents for their loved ones. They were tired of the usual, clichéd options and yearned for something more creative and thoughtful. One day, as they sat together, sipping coffee and discussing their shared dilemma, a curious, an orange cat wandered in.

As the cat lounged nearby, they noticed that it had a peculiar ability to come up with the most ingenious gift ideas. You see, this was no ordinary cat. This was NERO, a feline with an uncanny knack for understanding the art of gift-giving. And so, the friends made a pact to harness NERO's extraordinary powers and share them with the world.

With countless hours spent researching the mysteries of feline intuition and artificial intelligence, we were able to create a unique algorithm that mimics NERO's exceptional gift-giving abilities. By combining cutting-edge AI technology with NERO's whimsical and inventive spirit, our platform was born.

NERO the Gift Idea Generator is not just a website; it's a revolution in the world of gift-giving. We believe that every gift should be personal, memorable, and most importantly, filled with the same love and care that NERO brings to each of his suggestions. Our platform is designed to bring joy, not just to the recipients but also to the givers, who can now find the perfect gifts effortlessly.

As you embark on your gift-giving journey with NERO, remember that each idea has been crafted with the clever wit, boundless creativity, and feline elegance that only NERO can provide. So, say goodbye to the days of boring, predictable gifts and let NERO's magic whisk you away into the enchanting realm of perfect presents.

Welcome to the world of NERO, where cats and technology come together to make gift-giving a truly delightful experience.`;
  
  return (
    <Container>
      <div className="about-us-container">
        <h1>About Us</h1>
        <div className="about-us-content">{content}</div>
      </div>
    </Container>
  );
};

export default AboutUs;