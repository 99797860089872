import React from "react";
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import GiftList from "./GiftList";
import AmazonAd from './AmazonAd';
import './GiftIdeasPage.css';

const GiftIdeasPage = ({ giftIdeas, onGenerateMoreIdeas, loading }) => {
  const navigate = useNavigate();

  const handleGenerateMoreIdeas = async () => {
    await onGenerateMoreIdeas();
  };

  const handleBackHome = () => {
    navigate('/');
  };

  return (
    <div>
      <h1>Gift Ideas</h1>
      {loading ? (
        <Loading />
      ) : (
        <>
          <GiftList giftIdeas={giftIdeas} />
          <div className="buttons-container">
            <button onClick={handleGenerateMoreIdeas}>Generate more ideas</button>
            <button onClick={handleBackHome}>Back Home</button>
          </div>
          <AmazonAd />
        </>
      )}
    </div>
  );
};

export default GiftIdeasPage;
