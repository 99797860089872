import React from 'react';

const AmazonAd = () => {
  const adScript = `
    <script type="text/javascript">
      amzn_assoc_placement = "adunit0";
      amzn_assoc_tracking_id = "alejandro835-20";
      amzn_assoc_ad_mode = "search";
      amzn_assoc_ad_type = "smart";
      amzn_assoc_marketplace = "amazon";
      amzn_assoc_region = "US";
      amzn_assoc_default_search_phrase = "Gifts";
      amzn_assoc_default_category = "All";
      amzn_assoc_linkid = "78309c3c257e523ddbeef97430e8373b";
      amzn_assoc_design = "in_content";
      amzn_assoc_title = "Find Gifts on Amazon";
    </script>
    <script src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US"></script>
  `;
  return (
    <div>
      <iframe
        title="AmazonAd"
        srcDoc={adScript}
        width="100%"
        height="400px"
        scrolling="no"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default AmazonAd;
